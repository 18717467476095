<script>
  import { mdiCalendar, mdiClose, mdiFilterVariant } from "@mdi/js";
  import { defineComponent, watch } from "@vue/composition-api";
  import { PerfectScrollbar } from "vue2-perfect-scrollbar";

  // composables
  import {
    useFilterMenuToggle,
    useIdrDateFilter,
    useInvoicedFilter
  } from "@/components/common/filter-menu/useFilterMenu";
  import { store } from "@/store";

  export default defineComponent({
    components: {
      PerfectScrollbar
    },
    setup() {
      const {
        isFilterDrawerOpen,
        expandButtonLabel,
        clearFiltersButtonLabel,
        perfectScrollbarOptions,
        filtersPanel,
        filtersLength,
        toggleAll
      } = useFilterMenuToggle();

      const {
        selectedIdrDates,
        resetIdrDateFilter,
        idrDateMenu,
        dateRangeText
      } = useIdrDateFilter();

      const { filterInvoiced, resetInvoicedFilter } = useInvoicedFilter();

      const clearAllFilters = () => {
        resetIdrDateFilter();
        resetInvoicedFilter();
      };

      watch(selectedIdrDates, (value) => {
        store.dispatch("daily-logs-view/filterIdrDates", value);
      });

      watch(filterInvoiced, (value) => {
        store.dispatch("daily-logs-view/filterInvoiced", value);
      });

      return {
        clearAllFilters,
        isFilterDrawerOpen,
        expandButtonLabel,
        clearFiltersButtonLabel,
        perfectScrollbarOptions,
        filtersPanel,
        filtersLength,
        toggleAll,

        // useIdrDateFilter
        selectedIdrDates,
        idrDateMenu,
        resetIdrDateFilter,
        dateRangeText,

        // useInvoicedFilter
        filterInvoiced,
        resetInvoicedFilter,

        icons: {
          mdiFilterVariant,
          mdiCalendar,
          mdiClose
        }
      };
    }
  });
</script>
<template>
  <div class="app-customizer">
    <v-btn
      outlined
      color="primary"
      small
      @click="isFilterDrawerOpen = !isFilterDrawerOpen"
    >
      <v-icon left>{{ icons.mdiFilterVariant }}</v-icon>
      Filter List
    </v-btn>

    <!-- navigation drawer -->
    <v-navigation-drawer
      v-model="isFilterDrawerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      class="app-customizer-drawer"
    >
      <!-- heading -->
      <div class="app-customizer-header customizer-section py-3">
        <h6 class="font-weight-semibold text-xl primary--text">FILTERS</h6>
        <span class="text--secondary text-sm">
          Set your filters and view the results
        </span>
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isFilterDrawerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <!-- filter list content -->
      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!-- expand/collapse all -->
        <div class="text-center d-flex justify-space-between pb-4">
          <v-btn
            @click="clearAllFilters"
            x-small
            text
            class="text-xs secondary--text"
          >
            {{ clearFiltersButtonLabel }}
          </v-btn>
          <v-btn
            @click="toggleAll"
            x-small
            text
            class="text-xs secondary--text"
          >
            {{ expandButtonLabel }}
          </v-btn>
        </div>
        <div class="d-flex justify-start ma-3">
          <v-switch
            v-model="filterInvoiced"
            inset
            label="Show Invoiced Only"
          ></v-switch>
        </div>
        <v-divider></v-divider>
        <v-expansion-panels v-model="filtersPanel" accordion flat multiple tile>
          <!-- idr date -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex align-center justify-space-between">
                <span
                  :class="
                    selectedIdrDates.length > 0
                      ? 'primary--text text-xs font-weight-semibold'
                      : 'text-xs font-weight-semibold'
                  "
                >
                  IDR DATE
                </span>
                <v-chip
                  v-if="selectedIdrDates.length > 1"
                  class="mr-5"
                  close
                  small
                  color="primary"
                  @click:close="resetIdrDateFilter"
                >
                  {{ dateRangeText }}
                </v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-menu
                ref="menuref"
                v-model="idrDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Date Range"
                    hint="YYYY-MM-DD format"
                    persistent-hint
                    readonly
                    outlined
                    dense
                    :prepend-icon="icons.mdiCalendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="selectedIdrDates"
                  no-title
                  range
                  color="primary"
                >
                  <v-btn text color="primary" @click="idrDateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="idrDateMenu = false">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>
<style lang="scss" scoped>
  @import "~vuetify/src/styles/styles.sass";

  .ps-customizer {
    height: calc(100% - 81px) !important;
  }

  .app-customizer-toggler {
    position: fixed;
    top: 50%;
    transform: translateX(-50%);
    background: var(--v-primary-base);
    @include ltr() {
      right: -22px;
    }
    @include rtl() {
      left: 20px;
    }
  }

  @include theme(app-customizer-drawer) using ($material) {
    background-color: map-deep-get($material, "cards");
  }

  .app-customizer {
    z-index: 7;

    .v-label {
      font-size: 0.875rem;
    }

    .app-customizer-header {
      position: relative;
      .icon-customizer-close {
        position: absolute;
        @include ltr() {
          right: 20px;
        }
        @include rtl() {
          left: 20px;
        }
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .customizer-section {
      padding: 15px;
    }

    // Fixes Overlay is shown below SystemBar
    @at-root {
      .v-overlay {
        & + .v-application--wrap > .v-system-bar {
          z-index: 6 !important;
        }
      }
    }
  }

  .v-application.theme--light {
    .v-expansion-panel {
      &::before {
        box-shadow: none !important;
      }
      &--active {
        box-shadow: none !important;
      }
    }
  }
</style>
