<script>
  import { defineComponent } from "@vue/composition-api";

  import { formatCurrency } from "@/helpers/filter";

  export default defineComponent({
    props: {
      itemData: {
        type: Object,
        required: true
      }
    },
    setup() {
      return {
        formatCurrency
      };
    }
  });
</script>
<template>
  <v-simple-table dark dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left text-xs"></th>
          <th class="text-right text-xs">QTY</th>
          <th class="text-right text-xs">PER ITEM</th>
          <th class="text-right text-xs">TOTAL</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-xs">Actual</td>
          <td class="text-right text-xs">{{ itemData.actual_qty }}</td>
          <td class="text-right text-xs">
            {{ formatCurrency(itemData.item_cost) }}
          </td>
          <td class="text-right text-xs">
            {{ formatCurrency(itemData.actual_cost) }}
          </td>
        </tr>
        <tr>
          <td class="text-xs">Estimated</td>
          <td class="text-right text-xs">{{ itemData.estimate_qty }}</td>
          <td class="text-right text-xs">
            {{ formatCurrency(itemData.item_cost) }}
          </td>
          <td class="text-right text-xs">
            {{ formatCurrency(itemData.estimate_cost) }}
          </td>
        </tr>
        <tr>
          <td class="text-xs">Remaining</td>
          <td class="text-right text-xs">{{ itemData.remaining_qty }}</td>
          <td class="text-right text-xs">------</td>
          <td class="text-right text-xs">
            {{ formatCurrency(itemData.remaining_cost) }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
