var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dailyLogViewsByIdLoaded)?_c('div',[_c('v-card',{staticClass:"no-print",attrs:{"id":"invoice-list","flat":""}},[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between flex-wrap"},[_c('div',{staticClass:"d-flex title"},[_vm._v("Associated IDRs")]),_c('div',{staticClass:"d-flex"},[_c('idr-filter-menu'),_c('v-btn',{staticClass:"ml-3",attrs:{"small":"","outlined":"","color":"secondary"},on:{"click":function($event){return _vm.refreshDataTable()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiRefresh))])],1)],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dailyLogViewsByIdLatestQuery.response.data,"options":_vm.options,"server-items-length":_vm.dailyLogViewsByIdLatestQuery.response.total,"loading":_vm.dailyLogViewsByIdPending,"item-key":"fulcrum_id","footer-props":{
        itemsPerPageOptions: [5, 10, 25, 50]
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.idr_date",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{
            name: 'apps-daily-log-view',
            params: {
              project: item.project_id,
              record: item.const_id,
              idr: item.fulcrum_id
            }
          }}},[_vm._v(" "+_vm._s(item.idr_date)+" ")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(item.delay_reason !== null)?_c('v-icon',_vm._g({attrs:{"color":"error","small":""}},on),[_vm._v(" "+_vm._s(_vm.icons.mdiAlertOutline)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.delay_reason)+" delay")])])]}},{key:"item.invoiced",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.invoiceStatusColor(item.invoiced)) + "--text"),attrs:{"small":"","color":_vm.invoiceStatusColor(item.invoiced)}},[_vm._v(" "+_vm._s(item.invoiced ? "Invoiced" : "Not Invoiced")+" ")])]}}],null,true)})],1)],1):_c('div',[_c('app-loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }