import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { findAllProjectStatuses } from "@/store/queries/project-statuses";

const { ProjectStatus } = Vue.$FeathersVuex.api;

export function useProjectStatusList() {
  const projectStatusesParams = computed(findAllProjectStatuses);

  const { items: projectStatuses, isPending } = useFind({
    model: ProjectStatus,
    params: projectStatusesParams.value,
    qid: "projectStatusList"
  });

  return {
    projectStatuses,
    isPending
  };
}
