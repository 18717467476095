<script>
  import {
    mdiAccountHardHat,
    mdiAlertOutline,
    mdiClipboardPulseOutline
  } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { formatDate } from "@/helpers/filter";

  import { useProject } from "../../../project/useProject";

  export default defineComponent({
    props: {
      idrCount: {
        type: Number,
        required: true
      },
      workDelayCount: {
        type: Number,
        required: true
      },
      loggedItemsCount: {
        type: Number,
        required: true
      }
    },
    setup() {
      const { project, projectHasLoaded } = useProject();

      return {
        project,
        projectHasLoaded,
        formatDate,
        icons: {
          mdiAccountHardHat,
          mdiAlertOutline,
          mdiClipboardPulseOutline
        }
      };
    }
  });
</script>

<template>
  <v-card v-if="projectHasLoaded" flat>
    <div v-if="project.project_phase === 'Design'">Design!</div>
    <div v-else>
      <v-card-text class="align-start mb-8">
        <div class="d-flex justify-space-around">
          <!-- Work Delays -->
          <div class="d-flex align-center">
            <v-avatar size="44" color="error" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiAlertOutline }}
              </v-icon>
            </v-avatar>

            <div class="ms-3">
              <p class="text-xs mb-0">Work Delays</p>
              <h3 class="text-xl font-weight-bold">
                {{ workDelayCount }}
              </h3>
            </div>
          </div>

          <!-- IDRs created -->
          <div class="d-flex align-center">
            <v-avatar size="44" color="info" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiAccountHardHat }}
              </v-icon>
            </v-avatar>

            <div class="ms-3">
              <p class="text-xs mb-0">IDRs Completed</p>
              <h3 class="text-xl font-weight-bold">
                {{ idrCount }}
              </h3>
            </div>
          </div>

          <!-- Logged Item Count -->
          <div class="d-flex align-center">
            <v-avatar size="44" color="warning" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiClipboardPulseOutline }}
              </v-icon>
            </v-avatar>

            <div class="ms-3">
              <p class="text-xs mb-0">Items Logged</p>
              <h3 class="text-xl font-weight-bold">
                {{ loggedItemsCount }}
              </h3>
            </div>
          </div>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>
