<script>
  import {
    mdiCalculatorVariantOutline,
    mdiCurrencyUsd,
    mdiPercentOutline
  } from "@mdi/js";
  import { defineComponent } from "@vue/composition-api";

  import { formatCurrency, formatPercentage } from "@/helpers/filter";

  import { usePermitFinancial } from "./useConstructionRecordTabFinancial";

  export default defineComponent({
    setup() {
      const {
        permitFinancial,
        hasLoaded,
        isPending,
        resolvePercentBudgetColor
      } = usePermitFinancial();

      return {
        formatCurrency,
        formatPercentage,
        permitFinancial,
        hasLoaded,
        isPending,
        resolvePercentBudgetColor,
        icons: {
          mdiCalculatorVariantOutline,
          mdiCurrencyUsd,
          mdiPercentOutline
        }
      };
    }
  });
</script>

<template>
  <v-card flat>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Financial Summary</span>
      <v-spacer></v-spacer>
      <!-- <v-btn color="primary" class="me-3" small> Edit </v-btn> -->
    </v-card-title>
    <div v-if="hasLoaded">
      <v-card-text>
        <v-row>
          <v-col cols="6" md="3" class="d-flex align-center">
            <v-avatar size="44" color="info" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiCalculatorVariantOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Estimated Cost</p>
              <h3 class="text-xl font-weight-semibold">
                {{ formatCurrency(permitFinancial.estimate_cost) }}
              </h3>
            </div>
          </v-col>
          <v-col cols="6" md="3" class="d-flex align-center">
            <v-avatar size="44" color="info" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Actual Cost</p>
              <h3 class="text-xl font-weight-semibold">
                {{ formatCurrency(permitFinancial.actual_cost) }}
              </h3>
            </div>
          </v-col>
          <v-col cols="6" md="3" class="d-flex align-center">
            <v-avatar
              size="44"
              :color="
                resolvePercentBudgetColor(permitFinancial.remaining_percent)
              "
              rounded
              class="elevation-1"
            >
              <v-icon dark color="white" size="30">
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Remaining</p>
              <h3
                class="text-xl font-weight-semibold"
                :class="`${resolvePercentBudgetColor(
                  permitFinancial.remaining_percent
                )}--text`"
              >
                {{ formatCurrency(permitFinancial.remaining_cost) }}
              </h3>
            </div>
          </v-col>
          <v-col cols="6" md="3" class="d-flex align-center">
            <v-avatar
              size="44"
              :color="
                resolvePercentBudgetColor(permitFinancial.remaining_percent)
              "
              rounded
              class="elevation-1"
            >
              <v-icon dark color="white" size="30">
                {{ icons.mdiPercentOutline }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Remaining</p>
              <h3
                class="text-xl font-weight-semibold"
                :class="`${resolvePercentBudgetColor(
                  permitFinancial.remaining_percent
                )}--text`"
              >
                {{ Math.round(permitFinancial.remaining_percent) }}%
              </h3>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>
