/*******************
 * @file useFilterMenu
 * @author Anne Canoune
 * @version 0.1.0
 * @description This file contains all of the composable functions associated with
 * with the display and interactivity of the filter menu and the filters within.
 *
 */
import { mdiCheckboxBlankOutline, mdiCloseBox, mdiMinusBox } from "@mdi/js";
import { computed, nextTick, ref } from "@vue/composition-api";

import {
  useDesignerContactsList,
  useLeadInspectorContactsList
} from "@/composables/useContacts";
import { useFiscalYearList } from "@/composables/useFiscalYears";
import {
  useContractorOrganizationList,
  useDesignOrganizationList,
  useOrganizationList
} from "@/composables/useOrganizations";
import { useProjectStatusList } from "@/composables/useProjectStatuses";
import { useProjectTypeList } from "@/composables/useProjectTypes";
import { useRoleList } from "@/composables/useRoles";

/**
 * Use Filter Menu
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the expansion/collapse of the filter menu
 *
 */
export function useFilterMenuToggle() {
  const isFilterDrawerOpen = ref(false);
  const expandButtonLabel = ref("Expand All");
  const clearFiltersButtonLabel = ref("Clear Filters");
  const perfectScrollbarOptions = {
    maxScrollbarLength: 60,
    wheelPropagation: false
  };
  const filtersPanel = ref([]);
  const filtersLength = 7;

  const toggleAll = () => {
    if (filtersPanel.value.length == filtersLength) {
      // reset the panel
      filtersPanel.value = [];
      expandButtonLabel.value = "Expand All";
    } else {
      filtersPanel.value = [...Array(filtersLength).keys()].map((k, i) => i);
      expandButtonLabel.value = "Collapse All";
    }
  };

  return {
    isFilterDrawerOpen,
    expandButtonLabel,
    clearFiltersButtonLabel,
    perfectScrollbarOptions,
    filtersPanel,
    filtersLength,
    toggleAll
  };
}

/**
 * Use Search Text Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the free-form search text
 *
 */
export function useSearchTextFilter() {
  const filterText = ref(null);

  const resetTextFilter = async () => {
    filterText.value = null;
  };
  return { filterText, resetTextFilter };
}

/**
 * Use Organization Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of all organizations
 *
 */
export function useOrganizationFilter() {
  const { organizations } = useOrganizationList();
  const selectedOrganizations = ref([]);

  const allOrganizationsSelected = computed(() => {
    return selectedOrganizations.value.length === organizations.value.length;
  });

  const someOrganizationsSelected = computed(() => {
    return (
      selectedOrganizations.value.length > 0 && !allOrganizationsSelected.value
    );
  });

  const selectAllOrganizationsIcon = computed(() => {
    if (allOrganizationsSelected.value) return mdiCloseBox;
    if (someOrganizationsSelected.value) return mdiMinusBox;
    return mdiCheckboxBlankOutline;
  });

  const toggleAllOrganizations = async () => {
    if (allOrganizationsSelected.value) {
      selectedOrganizations.value = [];
    } else {
      selectedOrganizations.value = organizations.value
        .slice()
        .map((organization) => organization.fulcrum_id);
    }
    await nextTick();
  };

  const resetOrganizationFilter = async () => {
    selectedOrganizations.value = [];
    await nextTick();
  };

  return {
    organizations,
    selectedOrganizations,
    selectAllOrganizationsIcon,
    toggleAllOrganizations,
    resetOrganizationFilter
  };
}

/**
 * Use Contractor Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of contractors
 *
 */
export function useContractorFilter() {
  const { contractorOrganizations } = useContractorOrganizationList();
  const selectedContractors = ref([]);

  const allContractorsSelected = computed(() => {
    return (
      selectedContractors.value.length === contractorOrganizations.value.length
    );
  });

  const someContractorsSelected = computed(() => {
    return (
      selectedContractors.value.length > 0 && !allContractorsSelected.value
    );
  });

  const selectAllContractorsIcon = computed(() => {
    if (allContractorsSelected.value) return mdiCloseBox;
    if (someContractorsSelected.value) return mdiMinusBox;
    return mdiCheckboxBlankOutline;
  });

  const toggleAllContractors = async () => {
    if (allContractorsSelected.value) {
      selectedContractors.value = [];
    } else {
      selectedContractors.value = contractorOrganizations.value
        .slice()
        .map((contractor) => contractor.fulcrum_id);
    }
    await nextTick();
  };

  const resetContractorFilter = async () => {
    selectedContractors.value = [];
    await nextTick();
  };

  return {
    contractorOrganizations,
    selectedContractors,
    selectAllContractorsIcon,
    toggleAllContractors,
    resetContractorFilter
  };
}

/**
 * Use Design Firm Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of design firms
 *
 */
export function useDesignFirmFilter() {
  const { designFirms } = useDesignOrganizationList();
  const selectedDesignFirms = ref([]);

  const allDesignFirmsSelected = computed(() => {
    return selectedDesignFirms.value.length === designFirms.value.length;
  });

  const someDesignFirmsSelected = computed(() => {
    return (
      selectedDesignFirms.value.length > 0 && !allDesignFirmsSelected.value
    );
  });

  const selectAllDesignFirmsIcon = computed(() => {
    if (allDesignFirmsSelected.value) return mdiCloseBox;
    if (someDesignFirmsSelected.value) return mdiMinusBox;
    return mdiCheckboxBlankOutline;
  });

  const toggleAllDesignFirms = async () => {
    if (allDesignFirmsSelected.value) {
      selectedDesignFirms.value = [];
    } else {
      // we are querying `design_firm_record` in the `project` table
      selectedDesignFirms.value = designFirms.value
        .slice()
        .map((firm) => firm.fulcrum_id);
    }
    await nextTick();
  };

  const resetDesignFirmFilter = async () => {
    selectedDesignFirms.value = [];
    await nextTick();
  };

  return {
    designFirms,
    selectedDesignFirms,
    selectAllDesignFirmsIcon,
    toggleAllDesignFirms,
    resetDesignFirmFilter
  };
}

/**
 * Use Designer Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of designers
 *
 */
export function useDesignerFilter() {
  const { designerContacts, groupedDesignerContacts } =
    useDesignerContactsList();
  const selectedDesigners = ref([]);

  const allDesignersSelected = computed(() => {
    return selectedDesigners.value.length === designerContacts.value.length;
  });

  const someDesignersSelected = computed(() => {
    return selectedDesigners.value.length > 0 && !allDesignersSelected.value;
  });

  const selectAllDesignersIcon = computed(() => {
    if (allDesignersSelected.value) return mdiCloseBox;
    if (someDesignersSelected.value) return mdiMinusBox;
    return mdiCheckboxBlankOutline;
  });

  const toggleAllDesigners = async () => {
    if (allDesignersSelected.value) {
      selectedDesigners.value = [];
    } else {
      // we are querying `designer_record` in the `project` table
      // the array has been modified to include `{header: <org_name>}`
      // elements so we have to strip them out before we can run the query
      selectedDesigners.value = groupedDesignerContacts.value
        .slice()
        .filter((designer) => !designer.header)
        .map((designer) => designer.fulcrum_id);
    }
    await nextTick();
  };

  const resetDesignerFilter = async () => {
    selectedDesigners.value = [];
    await nextTick();
  };

  return {
    designerContacts,
    groupedDesignerContacts,
    selectedDesigners,
    selectAllDesignersIcon,
    toggleAllDesigners,
    resetDesignerFilter
  };
}

/**
 * Use Lead Inspector Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of lead inspectors
 *
 */
export function useLeadInspectorFilter() {
  const { leadInspectorContacts, groupedLeadInspectorContacts } =
    useLeadInspectorContactsList();
  const selectedLeadInspectors = ref([]);
  //const tempSelected = ref([]);

  const allLeadInspectorsSelected = computed(() => {
    return (
      selectedLeadInspectors.value.length == leadInspectorContacts.value.length
    );
  });

  const someLeadInspectorsSelected = computed(() => {
    //console.log(selectedLeadInspectors.value.length);
    return (
      selectedLeadInspectors.value.length > 0 &&
      !allLeadInspectorsSelected.value
    );
  });

  const selectAllLeadInspectorsIcon = computed(() => {
    if (allLeadInspectorsSelected.value) return mdiCloseBox;
    if (someLeadInspectorsSelected.value) return mdiMinusBox;
    return mdiCheckboxBlankOutline;
  });

  const toggleAllLeadInspectors = async () => {
    if (allLeadInspectorsSelected.value) {
      selectedLeadInspectors.value = [];
    } else {
      // we are querying `inspector_record` in the `project` table
      // the array has been modified to include `{header: <org_name>}`
      // elements so we have to strip them out before we can run the query
      selectedLeadInspectors.value = groupedLeadInspectorContacts.value
        .slice()
        .filter((inspector) => !inspector.header)
        .map((inspector) => inspector.fulcrum_id);
    }
    await nextTick();
  };

  const resetLeadInspectorFilter = async () => {
    selectedLeadInspectors.value = [];
    await nextTick();
  };

  return {
    leadInspectorContacts,
    groupedLeadInspectorContacts,
    selectedLeadInspectors,
    selectAllLeadInspectorsIcon,
    toggleAllLeadInspectors,
    //allLeadInspectorsSelected,
    resetLeadInspectorFilter
  };
}

/**
 * Use Fiscal Year Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of fiscal years
 *
 */
export function useFiscalYearFilter() {
  const { fiscalYears } = useFiscalYearList();
  const selectedFiscalYears = ref([]);

  const allFiscalYearsSelected = computed(() => {
    return selectedFiscalYears.value.length === fiscalYears.value.length;
  });

  const someFiscalYearsSelected = computed(() => {
    return (
      selectedFiscalYears.value.length > 0 && !allFiscalYearsSelected.value
    );
  });

  const selectAllFiscalYearsIcon = computed(() => {
    if (allFiscalYearsSelected.value) return mdiCloseBox;
    if (someFiscalYearsSelected.value) return mdiMinusBox;
    return mdiCheckboxBlankOutline;
  });

  const toggleAllFiscalYears = async () => {
    if (allFiscalYearsSelected.value) {
      selectedFiscalYears.value = [];
    } else {
      selectedFiscalYears.value = fiscalYears.value
        .slice()
        .map((year) => year.display_year);
    }
    await nextTick();
  };

  const resetFiscalYearFilter = async () => {
    selectedFiscalYears.value = [];
    await nextTick();
  };

  return {
    fiscalYears,
    selectedFiscalYears,
    selectAllFiscalYearsIcon,
    toggleAllFiscalYears,
    resetFiscalYearFilter
  };
}

/**
 * Use Project Type Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of project types
 *
 */
export function useProjectTypeFilter() {
  const { projectTypes } = useProjectTypeList();
  const selectedProjectTypes = ref([]);

  const allProjectTypesSelected = computed(() => {
    return selectedProjectTypes.value.length === projectTypes.value.length;
  });

  const someProjectTypesSelected = computed(() => {
    return (
      selectedProjectTypes.value.length > 0 && !allProjectTypesSelected.value
    );
  });

  const selectAllProjectTypesIcon = computed(() => {
    if (allProjectTypesSelected.value) return mdiCloseBox;
    if (someProjectTypesSelected.value) return mdiMinusBox;
    return mdiCheckboxBlankOutline;
  });

  const toggleAllProjectTypes = async () => {
    //console.debug("All projects types currently selected: ", allProjectTypesSelected.value);
    if (allProjectTypesSelected.value) {
      selectedProjectTypes.value = [];
    } else {
      // when we select `all project types` we have an array of objects which we must
      // transform to an array for use in the `$in: []` statement
      selectedProjectTypes.value = projectTypes.value
        .slice()
        .map((type) => type.display_type);
      //console.debug(require('util').inspect(selectedProjectTypes.value, false, 15));
    }
    await nextTick();
  };

  const resetProjectTypeFilter = async () => {
    selectedProjectTypes.value = [];
    await nextTick();
  };

  return {
    projectTypes,
    selectedProjectTypes,
    selectAllProjectTypesIcon,
    toggleAllProjectTypes,
    resetProjectTypeFilter
  };
}

/**
 * Use Project Status Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of project statuses
 *
 */
export function useProjectStatusFilter() {
  const { projectStatuses } = useProjectStatusList();
  const selectedProjectStatuses = ref([]);

  const allProjectStatusesSelected = computed(() => {
    return (
      selectedProjectStatuses.value.length === projectStatuses.value.length
    );
  });
  const someProjectStatusesSelected = computed(() => {
    return (
      selectedProjectStatuses.value.length > 0 &&
      !allProjectStatusesSelected.value
    );
  });
  const selectAllProjectStatusesIcon = computed(() => {
    if (allProjectStatusesSelected.value) return mdiCloseBox;
    if (someProjectStatusesSelected.value) return mdiMinusBox;
    return mdiCheckboxBlankOutline;
  });
  const toggleAllProjectStatuses = async () => {
    if (allProjectStatusesSelected.value) {
      selectedProjectStatuses.value = [];
    } else {
      selectedProjectStatuses.value = projectStatuses.value
        .slice()
        .map((status) => status.status);
    }
    await nextTick();
  };

  const resetProjectStatusFilter = async () => {
    selectedProjectStatuses.value = [];
    await nextTick();
  };

  return {
    projectStatuses,
    selectedProjectStatuses,
    selectAllProjectStatusesIcon,
    toggleAllProjectStatuses,
    resetProjectStatusFilter
  };
}

/**
 * Use IDR Date Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of IDRs
 *
 */
export function useIdrDateFilter() {
  const selectedIdrDates = ref([]);
  const idrDateMenu = ref(false);

  const resetIdrDateFilter = async () => {
    selectedIdrDates.value = [];
    await nextTick();
  };

  const dateRangeText = computed(() => {
    return selectedIdrDates.value.join(" ~ ");
  });

  return {
    selectedIdrDates,
    resetIdrDateFilter,
    idrDateMenu,
    dateRangeText
  };
}

/**
 * Use Invoiced Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of IDRs that have been invoiced
 *
 */
export function useInvoicedFilter() {
  const filterInvoiced = ref(false);

  const resetInvoicedFilter = async () => {
    filterInvoiced.value = false;
    await nextTick();
  };

  return { filterInvoiced, resetInvoicedFilter };
}

/**
 * Use Percent Remaining Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of permit items
 *              based on the percentage of funds remaining
 *
 */
export function usePercentRemainingFilter() {
  const filterPercentRemainRange = ref([0, 0]);

  return {
    filterPercentRemainRange
  };
}

/**
 * Use Over Under Estimate Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of permit items
 *              based on whether the actual quantity is over or
 *              under the estimated quantity
 *
 */
export function useOverUnderEstimateFilter() {
  const filterOverUnderEstimate = ref(null);

  return {
    filterOverUnderEstimate
  };
}

/**
 * Use Over Under Estimate Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of permit items
 *              based on the status of the item.
 *
 */
export function useItemStatusFilter() {
  const filterItemStatuses = ref([]);

  return {
    filterItemStatuses
  };
}

/**
 * Use Role Filter
 *
 * @author Anne Canoune
 * @version 0.1.0
 * @description Controls the display and selection of roles.
 *
 */
export function useRoleFilter() {
  const { roles } = useRoleList();
  const selectedRoles = ref([]);

  const allRolesSelected = computed(() => {
    return selectedRoles.value.length === roles.value.length;
  });
  const someRolesSelected = computed(() => {
    return selectedRoles.value.length > 0 && !allRolesSelected.value;
  });
  const selectAllRolesIcon = computed(() => {
    if (allRolesSelected.value) return mdiCloseBox;
    if (someRolesSelected.value) return mdiMinusBox;
    return mdiCheckboxBlankOutline;
  });
  const toggleAllRoles = async () => {
    if (allRolesSelected.value) {
      selectedRoles.value = [];
    } else {
      selectedRoles.value = roles.value.slice().map((role) => role.id);
    }
    await nextTick();
  };

  const resetRoleFilter = async () => {
    selectedRoles.value = [];
    await nextTick();
  };

  return {
    roles,
    selectedRoles,
    selectAllRolesIcon,
    toggleAllRoles,
    resetRoleFilter
  };
}
