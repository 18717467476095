<script>
  import {
    mdiAlertOutline,
    mdiFilterOutline,
    mdiMagnify,
    mdiMapMarkerOutline,
    mdiPrinterOutline,
    mdiRefresh,
    mdiUndoVariant
  } from "@mdi/js";
  import { defineComponent, watch } from "@vue/composition-api";
  import Vue from "vue";

  import AppLoading from "@/components/base/loading/AppLoading.vue";
  // import {
  //   useIdrDatatable,
  //   useIdrListbyId
  // } from "@/views/apps/daily-log/useIdr.js";
  import { snackbar } from "@/components/base/snackbar/useSnackbar";
  import { usePagination } from "@/composables/usePagination";
  import { useRouter } from "@/helpers";
  import { weatherIcon } from "@/helpers/iconTypes.js";
  import { store } from "@/store";
  // import { useDailyLogInvoice } from "@/views/apps/daily-log/daily-log-list/useDailyLogInvoice";
  import {
    useDailyLogViewListById,
    useDailyLogViewListByIdDatatable
  } from "@/views/apps/daily-log/daily-log-list/useDailyLogList";

  // import PrintDailyLogReport from "@/views/apps/daily-log/PrintDailyLogReport.vue";
  import IdrFilterMenu from "./idr-filter-menu/IdrFilterMenu.vue";

  export default defineComponent({
    components: {
      IdrFilterMenu,
      // PrintDailyLogReport,
      AppLoading
    },
    setup() {
      const { route } = useRouter();
      const projectId = route.value.params.project;
      const constId = route.value.params.record;
      // const singleSelect = ref(false);
      // const selectedItems = ref([]);
      const invoiceStatusColor = (invoiced) => {
        if (invoiced === true) return "success";
        if (invoiced === false) return "default";
      };
      const { DailyLogInvoice } = Vue.$FeathersVuex.api;
      //const { latestQuery, dailyLogsHaveLoaded, dailyLogsArePending } = useIdrListbyId();
      //const { headers } = useIdrDatatable();
      const {
        dailyLogViewsById,
        dailyLogViewsByIdLoaded,
        dailyLogViewsByIdPending,
        dailyLogViewsByIdLatestQuery,
        findDailyLogViewsById,
        dailyLogViewListByIdParams
      } = useDailyLogViewListById();
      const { headers } = useDailyLogViewListByIdDatatable();
      const { selectedNumberOfItemsPerPage, page, options } = usePagination();
      // const { processFormData, undoMarkAsInvoiced } = useDailyLogInvoice();
      // const printItem = ref(null);

      // const handlePrint = (item) => {
      //   printItem.value = item;
      //   setTimeout(() => {
      //     window.print();
      //   }, 500);
      // };
      // const handleMarkAsInvoiced = async () => {
      //   try {
      //     store.dispatch("daily-log-invoices/updateLoading", true);
      //     //console.debug("marking as invoiced");
      //     //console.debug(selectedItems.value);
      //     processFormData(selectedItems.value);
      //     // snackbar({
      //     //   text: `Items successfully marked as 'Invoiced'. Have a nice day!`,
      //     //   color: "success"
      //     // });
      //   } catch (error) {
      //     snackbar({
      //       text: `Unable to mark items as invoiced. Please contact your Administrator.`,
      //       color: "error"
      //     });
      //   } finally {
      //     selectedItems.value = [];
      //     store.dispatch("daily-log-invoices/updateLoading", false);
      //   }
      // };

      // const handleUnmarkAsInvoiced = async (item) => {
      //   try {
      //     store.dispatch("daily-log-invoices/updateLoading", true);
      //     console.debug("unmarking as invoiced");
      //     console.debug(item);
      //     undoMarkAsInvoiced(item);
      //   } catch (error) {
      //     snackbar({
      //       text: `Unable to mark items as invoiced. Please contact your Administrator.`,
      //       color: "error"
      //     });
      //   } finally {
      //     selectedItems.value = [];
      //     store.dispatch("daily-log-invoices/updateLoading", false);
      //   }
      // };

      watch(options, (value) => {
        store.dispatch("daily-logs-view/updatePagination", value);
      });

      DailyLogInvoice.on("patched", async () => {
        await findDailyLogViewsById(dailyLogViewListByIdParams);
      });

      const refreshDataTable = async () => {
        await findDailyLogViewsById(dailyLogViewListByIdParams);
        snackbar({
          text: `Daily Log Views successfully reloaded. Enabled filters have been preserved.`,
          color: "info"
        });
      };

      return {
        projectId,
        constId,
        headers,
        //printItem,
        //handlePrint,
        // latestQuery,
        // dailyLogsHaveLoaded,
        // dailyLogsArePending,

        dailyLogViewsById,
        dailyLogViewsByIdLoaded,
        dailyLogViewsByIdPending,
        dailyLogViewsByIdLatestQuery,
        findDailyLogViewsById,
        dailyLogViewListByIdParams,
        // usePagination
        selectedNumberOfItemsPerPage,
        page,
        options,
        //singleSelect,
        //selectedItems,
        invoiceStatusColor,
        // handleMarkAsInvoiced,
        // handleUnmarkAsInvoiced,
        refreshDataTable,
        weatherIcon,
        icons: {
          mdiAlertOutline,
          mdiFilterOutline,
          mdiMagnify,
          mdiMapMarkerOutline,
          mdiPrinterOutline,
          mdiRefresh,
          mdiUndoVariant
        }
      };
    }
  });
</script>
<template>
  <div v-if="dailyLogViewsByIdLoaded">
    <!-- <print-daily-log-report class="idr-report" :item="printItem" /> -->
    <v-card id="invoice-list" class="no-print" flat>
      <v-card-text class="d-flex align-center justify-space-between flex-wrap">
        <div class="d-flex title">Associated IDRs</div>
        <div class="d-flex">
          <idr-filter-menu></idr-filter-menu>

          <v-btn
            small
            outlined
            color="secondary"
            class="ml-3"
            @click="refreshDataTable()"
          >
            <v-icon>{{ icons.mdiRefresh }}</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="dailyLogViewsByIdLatestQuery.response.data"
        :options.sync="options"
        :server-items-length="dailyLogViewsByIdLatestQuery.response.total"
        :loading="dailyLogViewsByIdPending"
        item-key="fulcrum_id"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50]
        }"
      >
        <!-- idr -->
        <template #[`item.idr_date`]="{ item }">
          <router-link
            :to="{
              name: 'apps-daily-log-view',
              params: {
                project: item.project_id,
                record: item.const_id,
                idr: item.fulcrum_id
              }
            }"
            class="primary--text font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.idr_date }}
          </router-link>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                color="error"
                v-if="item.delay_reason !== null"
                v-on="on"
                small
              >
                {{ icons.mdiAlertOutline }}
              </v-icon>
            </template>
            <span>{{ item.delay_reason }} delay</span>
          </v-tooltip>
        </template>

        <!-- status -->
        <template #[`item.invoiced`]="{ item }">
          <v-chip
            small
            :color="invoiceStatusColor(item.invoiced)"
            :class="`${invoiceStatusColor(item.invoiced)}--text`"
            class="v-chip-light-bg"
          >
            {{ item.invoiced ? "Invoiced" : "Not Invoiced" }}
          </v-chip>
        </template>

        <!-- card actions -->
        <!-- <template #[`item.actions`]="{ item }">
          <div class="d-flex justify-space-between">
            <v-btn class="d-flex" icon x-small>
              <v-icon color="primary" @click="handlePrint(item)">
                {{ icons.mdiPrinterOutline }}
              </v-icon>
            </v-btn>
            <v-tooltip v-if="item.invoiced === true" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="d-flex" icon x-small v-bind="attrs" v-on="on">
                  <v-icon color="primary" @click="handleUnmarkAsInvoiced(item)">
                    {{ icons.mdiUndoVariant }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Undo Mark as Invoiced</span>
            </v-tooltip>
          </div>
        </template> -->
      </v-data-table>
    </v-card>
  </div>
  <div v-else>
    <app-loading></app-loading>
  </div>
</template>
<style scoped lang="scss">
  .idr-report {
    display: none;
  }
  @media print {
    body {
      font-size: 16px !important;
      font-family: "Inter, sans-serif" !important;
    }
    // .v-application {
    //   background: none !important;
    // }
    .app-navigation-menu,
    .v-app-bar,
    .info-panel,
    .v-footer {
      display: none !important;
    }
    .v-main,
    .app-content-container {
      padding: 0px !important;
    }
    .no-print {
      display: none !important;
    }
    .idr-report {
      // max-width: 100% !important;
      // flex: 1 !important;
      display: block;
    }
  }
</style>
