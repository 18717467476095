<script>
  import { mdiClose, mdiFilterVariant } from "@mdi/js";
  import { defineComponent, watch } from "@vue/composition-api";
  import { watchDebounced } from "@vueuse/core";
  import { PerfectScrollbar } from "vue2-perfect-scrollbar";

  import {
    useFilterMenuToggle,
    useItemStatusFilter,
    useOverUnderEstimateFilter,
    usePercentRemainingFilter,
    useSearchTextFilter
  } from "@/components/common/filter-menu/useFilterMenu";
  import { store } from "@/store";

  export default defineComponent({
    components: {
      PerfectScrollbar
    },
    setup() {
      const {
        isFilterDrawerOpen,
        expandButtonLabel,
        clearFiltersButtonLabel,
        perfectScrollbarOptions,
        filtersPanel,
        filtersLength,
        toggleAll
      } = useFilterMenuToggle();

      const { filterText, resetTextFilter } = useSearchTextFilter();
      const { filterPercentRemainRange } = usePercentRemainingFilter();
      const { filterOverUnderEstimate } = useOverUnderEstimateFilter();
      const { filterItemStatuses } = useItemStatusFilter();

      const clearPercentRemainRange = () => {
        filterPercentRemainRange.value = [0, 0];
      };

      const clearOverUnderEstimate = () => {
        filterOverUnderEstimate.value = null;
      };

      const clearItemStatuses = () => {
        filterItemStatuses.value = [];
      };

      const clearAllFilters = () => {
        resetTextFilter();
        clearPercentRemainRange();
        clearOverUnderEstimate();
        clearItemStatuses();
        store.dispatch("permit-item-financials/clearAllFilters");
      };

      watchDebounced(
        filterText,
        (value) => {
          store.dispatch("permit-item-financials/filterText", value);
        },
        { debounce: 500 }
      );
      watch(filterItemStatuses, (value) => {
        store.dispatch("permit-item-financials/updateItemStatuses", value);
      });
      watch(filterOverUnderEstimate, (value) => {
        store.dispatch("permit-item-financials/updateOverUnderEstimate", value);
      });
      watchDebounced(
        filterPercentRemainRange,
        (value) => {
          store.dispatch(
            "permit-item-financials/updatePercentRemainRange",
            value
          );
        },
        { debounce: 500 }
      );

      return {
        clearAllFilters,

        // useFilterMenuToggle
        isFilterDrawerOpen,
        expandButtonLabel,
        clearFiltersButtonLabel,
        perfectScrollbarOptions,
        filtersPanel,
        filtersLength,
        toggleAll,

        // useSearchTextFilter
        filterText,
        resetTextFilter,

        // usePercentRemainingFilter
        filterPercentRemainRange,
        clearPercentRemainRange,

        // useOverUnderEstimateFilter
        filterOverUnderEstimate,
        clearOverUnderEstimate,

        // useItemStatusFilter
        filterItemStatuses,
        clearItemStatuses,

        // icons
        icons: {
          mdiClose,
          mdiFilterVariant
        }
      };
    }
  });
</script>

<template>
  <div class="app-customizer">
    <v-btn
      small
      outlined
      color="primary"
      @click="isFilterDrawerOpen = !isFilterDrawerOpen"
    >
      <v-icon left>{{ icons.mdiFilterVariant }}</v-icon>
      Filter List
    </v-btn>

    <!-- navigation drawer -->
    <v-navigation-drawer
      v-model="isFilterDrawerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      class="app-customizer-drawer"
    >
      <!-- heading -->
      <div class="app-customizer-header customizer-section py-3">
        <h6 class="font-weight-semibold text-xl text--primary">FILTERS</h6>
        <span class="text--secondary">
          Set your filters and view the results
        </span>
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isFilterDrawerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>
      <!-- filter list content -->
      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!-- text search -->
        <div class="customizer-section">
          <v-text-field
            v-model="filterText"
            :prepend-inner-icon="icons.mdiMagnify"
            clearable
            single-line
            dense
            outlined
            hide-details
            placeholder="Search items by item number or description"
          ></v-text-field>
        </div>

        <!-- expand/collapse all -->
        <div class="text-center d-flex justify-space-between pb-4">
          <v-btn
            @click="clearAllFilters"
            x-small
            text
            class="text-xs secondary--text"
          >
            {{ clearFiltersButtonLabel }}
          </v-btn>
          <v-btn
            @click="toggleAll"
            x-small
            text
            class="text-xs secondary--text"
          >
            {{ expandButtonLabel }}
          </v-btn>
        </div>

        <v-expansion-panels v-model="filtersPanel" accordion flat multiple tile>
          <!-- item status -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex align-center justify-space-between">
                <span
                  :class="
                    filterItemStatuses.length > 0
                      ? 'primary--text text-xs font-weight-semibold'
                      : 'text-xs font-weight-semibold'
                  "
                >
                  ITEM STATUS
                </span>
                <v-chip
                  v-if="filterItemStatuses.length > 0"
                  class="mr-5"
                  close
                  small
                  color="primary"
                  @click:close="clearItemStatuses"
                >
                  {{ filterItemStatuses.length }}
                </v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="black--text my-1">
                <v-checkbox
                  v-model="filterItemStatuses"
                  dense
                  label="Not In-Bid"
                  value="NIB"
                ></v-checkbox>
                <v-checkbox
                  v-model="filterItemStatuses"
                  dense
                  label="In-Bid"
                  value="In-Bid"
                ></v-checkbox>
                <v-checkbox
                  v-model="filterItemStatuses"
                  dense
                  label="Short List"
                  value="Short List"
                ></v-checkbox>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- over/under estimated quantity -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex align-center justify-space-between">
                <span
                  :class="
                    filterOverUnderEstimate !== null
                      ? 'primary--text text-xs font-weight-semibold'
                      : 'text-xs font-weight-semibold'
                  "
                >
                  QUANTITY
                </span>
                <v-chip
                  v-if="filterOverUnderEstimate !== null"
                  class="mr-5"
                  close
                  small
                  color="primary"
                  @click:close="clearOverUnderEstimate"
                >
                  1
                </v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="my-1">
                <v-radio-group v-model="filterOverUnderEstimate" dense>
                  <v-radio
                    label="Actual is equal to or below estimated quantity"
                    value="greaterThanOrEqual"
                  ></v-radio>
                  <v-radio
                    label="Actual is above estimated quantity"
                    value="lessThan"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex align-center justify-space-between">
                <span
                  :class="
                    filterPercentRemainRange[1] > 0
                      ? 'primary--text text-xs font-weight-semibold'
                      : 'text-xs font-weight-semibold'
                  "
                  >COST FILTERS</span
                >
                <v-chip
                  v-if="filterPercentRemainRange[1] > 0"
                  class="mr-5"
                  close
                  small
                  color="primary"
                  @click:close="clearPercentRemainRange"
                >
                  1
                </v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="black--text my-1">
                Percentage of funds remaining is between
                <v-expand-transition>
                  <span v-if="filterPercentRemainRange[1] > 0">
                    <strong>{{ filterPercentRemainRange[0] }}</strong
                    >% and <strong>{{ filterPercentRemainRange[1] }}</strong
                    >%.
                  </span>
                </v-expand-transition>
                <div class="mt-10">
                  <v-range-slider
                    v-model="filterPercentRemainRange"
                    max="100"
                    min="0"
                    thumb-size="28"
                    track-fill-color="primary"
                    track-color="primary"
                    thumb-label="always"
                  ></v-range-slider>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
  @import "~vuetify/src/styles/styles.sass";

  .ps-customizer {
    height: calc(100% - 81px) !important;
  }

  .app-customizer-toggler {
    position: fixed;
    top: 50%;
    transform: translateX(-50%);
    background: var(--v-primary-base);
    @include ltr() {
      right: -22px;
    }
    @include rtl() {
      left: 20px;
    }
  }

  @include theme(app-customizer-drawer) using ($material) {
    background-color: map-deep-get($material, "cards");
  }

  .app-customizer {
    z-index: 7;

    .v-label {
      font-size: 0.875rem;
    }

    .app-customizer-header {
      position: relative;
      .icon-customizer-close {
        position: absolute;
        @include ltr() {
          right: 20px;
        }
        @include rtl() {
          left: 20px;
        }
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .customizer-section {
      padding: 15px;
    }

    // Fixes Overlay is shown below SystemBar
    @at-root {
      .v-overlay {
        & + .v-application--wrap > .v-system-bar {
          z-index: 6 !important;
        }
      }
    }
  }

  .v-application.theme--light {
    .v-expansion-panel {
      &::before {
        box-shadow: none !important;
      }
      &--active {
        box-shadow: none !important;
      }
    }
  }
</style>
