/**
 * @file Project Statuses
 * @author Anne Canoune
 * @version 0.1.0
 * @description All of the queries associated with the Project Statuses service
 */

/**
 * Find All Project Statuses
 * @description Returns all project statuses.
 *
 *
 * @returns {object}
 */

function findAllProjectStatuses() {
  return {
    query: {
      $select: [
        "id",
        "status",
        "display_status",
        "display_order",
        "display_color"
      ],
      $sort: {
        display_order: 1
      }
    }
  };
}
exports.findAllProjectStatuses = findAllProjectStatuses;
