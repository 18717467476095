<script>
  import {
    mdiAccountHardHatOutline,
    mdiBriefcaseVariantOutline,
    mdiBulldozer,
    mdiCalendarBlankOutline,
    mdiCheckDecagramOutline,
    mdiCommentOutline,
    mdiFileSign,
    mdiFlagVariantOutline,
    mdiOfficeBuildingOutline,
    mdiPencilRuler
  } from "@mdi/js";
  import { computed, defineComponent } from "@vue/composition-api";

  import { permitTypeIcon } from "@/composables/usePermitTypes.js";
  import { permitStatusColor } from "@/helpers/statusColors";
  import useVuetify from "@/helpers/vuetify";
  import { useProject } from "@/views/apps/project/useProject";

  export default defineComponent({
    props: {
      constructionRecord: {
        type: Object,
        required: true
      }
    },
    setup() {
      const { rootThemeClasses } = useVuetify();

      const { project, projectHasLoaded, isPending } = useProject();
      const overlayIsVisible = computed(() => {
        if (!projectHasLoaded) {
          return true;
        } else {
          return false;
        }
      });

      return {
        rootThemeClasses,
        permitStatusColor,
        permitTypeIcon,
        project,
        projectHasLoaded,
        isPending,
        overlayIsVisible,
        icons: {
          mdiPencilRuler,
          mdiOfficeBuildingOutline,
          mdiBulldozer,
          mdiAccountHardHatOutline,
          mdiFlagVariantOutline,
          mdiFileSign,
          mdiCalendarBlankOutline,
          mdiBriefcaseVariantOutline,
          mdiCheckDecagramOutline,
          mdiCommentOutline
        }
      };
    }
  });
</script>

<template>
  <v-row class="no-print permit-info-panel">
    <!-- permit summary -->
    <v-col cols="12">
      <v-card v-if="projectHasLoaded" flat class="pt-10">
        <v-card-title class="justify-center flex-column">
          <span class="my-2 primary--text">{{
            constructionRecord.permit_num
          }}</span>
          <v-chip
            label
            :class="`v-chip-light-bg text-sm font-weight-semibold my-2`"
          >
            <v-icon>{{
              permitTypeIcon(constructionRecord.permit_type[0])
            }}</v-icon>
            {{ constructionRecord.permit_type[0] }}
          </v-chip>
        </v-card-title>

        <!-- Description -->
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-0">Description</h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-3">
              <span
                v-if="constructionRecord.permit_desc"
                class="font-weight-medium"
              >
                {{ constructionRecord.permit_desc }}
              </span>
              <span v-else class="font-weight-medium">
                No description provided.
              </span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <!-- Details -->
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-0">Details</h2>
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiPencilRuler }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Designer: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ project.designer_nm }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Design Firm -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiOfficeBuildingOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Design Firm: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ project.design_firm_nm }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Contractor -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiBulldozer }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Contractor: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ project.contractor_nm }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Lead Inspector -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiAccountHardHatOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Lead Inspector: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ project.inspector_nm }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Permit Status -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiFlagVariantOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Status: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text--secondary">
                  <v-chip
                    small
                    label
                    :color="permitStatusColor(constructionRecord.status)"
                    :class="`v-chip-light-bg ${permitStatusColor(
                      constructionRecord.status
                    )}--text font-weight-medium text-capitalize`"
                  >
                    {{ constructionRecord.status }}
                  </v-chip>
                </span>
              </v-list-item-content>
            </v-list-item>
            <!-- Contract Number -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiFileSign }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Contract #: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ project.contract_num }}
                </span>
              </v-list-item-content>
            </v-list-item>
            <!-- Project Number -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiBriefcaseVariantOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Project: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ constructionRecord.project_name }}
                  (#{{ constructionRecord.project_num }})
                </span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <!-- ADA -->
        <v-card-text>
          <v-list>
            <h2 class="text-xl font-weight-semibold mt-5">ADA</h2>
            <v-divider></v-divider>

            <!-- Acceptance -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiCheckDecagramOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Acceptance: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ constructionRecord.ada_acceptance }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Acceptance Date -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiCalendarBlankOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Date: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ constructionRecord.ada_date }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <!-- Comment -->
            <v-list-item dense class="px-0 mb-n2">
              <v-list-item-avatar class="ma-0">
                <v-icon small>{{ icons.mdiCommentOutline }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content> Comment: </v-list-item-content>
              <v-list-item-content>
                <span class="font-weight-medium text-capitalize">
                  {{ constructionRecord.ada_comment }}
                </span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <v-card v-if="isPending" height="681">
        <v-overlay absolute color="white" opacity="0.75">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-col>
  </v-row>
</template>
