<script>
  import { defineComponent } from "@vue/composition-api";

  import ConstructionRecordSummaryInfo from "@/views/apps/construction-record/construction-record-view/construction-record-summary-info/ConstructionRecordSummaryInfo.vue";

  export default defineComponent({
    props: {
      constructionRecord: {
        type: Object,
        required: true
      }
    },
    components: {
      ConstructionRecordSummaryInfo
    },
    setup() {}
  });
</script>

<template>
  <div class="no-print user-tab-overview">
    <v-row>
      <v-col cols="12">
        <v-card min-height="500" flat>
          <v-card-title class="align-start mb-8">
            <span class="font-weight-semibold">
              Construction Site Summary
            </span>

            <v-spacer></v-spacer>

            <v-chip
              class="v-chip-light-bg primary--text font-weight-semibold"
              small
            >
              Cumulative View
            </v-chip>
          </v-card-title>

          <!-- Summary Stats -->
          <construction-record-summary-info
            :idr-count="constructionRecord.idr_count"
            :work-delay-count="constructionRecord.work_delay_count"
            :logged-items-count="constructionRecord.logged_items_count"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
