import { computed } from "@vue/composition-api";
import { useFind, useGet } from "feathers-vuex";
import Vue from "vue";

import { useRouter } from "@/helpers";
import { store } from "@/store";
const { PermitFinancial, PermitItemFinancial } = Vue.$FeathersVuex.api;

export function usePermitFinancial() {
  const { route } = useRouter();

  const {
    item: permitFinancial,
    hasLoaded,
    isPending
  } = useGet({
    model: PermitFinancial,
    id: route.value.params.record
  });

  const resolvePercentBudgetColor = (percent) => {
    if (percent < 0) {
      return "error";
    } else if (percent >= 0 && percent <= 25) {
      return "warning";
    } else {
      return "success";
    }
  };

  return {
    permitFinancial,
    hasLoaded,
    isPending,
    resolvePercentBudgetColor
  };
}

export function usePermitFinancialList() {
  const { route } = useRouter();

  const permitItemFinancialsParams = computed(() => {
    let q = {
      query: {
        $limit: store.state["permit-item-financials"].pageLimit,
        $skip: store.state["permit-item-financials"].pageSkip,
        const_id: route.value.params.record
      }
    };

    let $and = [];
    if (store.state["permit-item-financials"].filterText !== null) {
      $and = [
        {
          $or: [
            {
              item_num: {
                $iLike: store.state["permit-item-financials"].filterText + "%"
              }
            },
            {
              item_desc: {
                $iLike:
                  "%" + store.state["permit-item-financials"].filterText + "%"
              }
            }
          ]
        }
      ];
    }

    if (
      store.state["permit-item-financials"].filterPercentRemainRange[1] !== 0
    ) {
      $and.push({
        $and: [
          {
            remaining_percent: {
              $gte: store.state["permit-item-financials"]
                .filterPercentRemainRange[0]
            }
          },
          {
            remaining_percent: {
              $lte: store.state["permit-item-financials"]
                .filterPercentRemainRange[1]
            }
          }
        ]
      });
    }

    if (
      store.state["permit-item-financials"].filterOverUnderEstimate ==
      "greaterThanOrEqual"
    ) {
      $and.push({
        remaining_qty: {
          $gte: 0
        }
      });
    }

    if (
      store.state["permit-item-financials"].filterOverUnderEstimate ==
      "lessThan"
    ) {
      $and.push({
        remaining_qty: {
          $lt: 0
        }
      });
    }

    if (store.state["permit-item-financials"].filterItemStatuses.length > 0) {
      console.log(
        store.state["permit-item-financials"].filterItemStatuses.length
      );
      $and.push({
        status: {
          $in: store.state["permit-item-financials"].filterItemStatuses
        }
      });
    }

    Object.assign(q.query, { $and });
    console.log(q);
    return q;
  });
  const {
    items: permitItemFinancials,
    isPending: permitItemFinancialsArePending,
    haveLoaded: permitItemFinancialsHaveLoaded,
    paginationData,
    latestQuery
  } = useFind({
    model: PermitItemFinancial,
    params: permitItemFinancialsParams,
    paginate: true,
    qid: "permitItemFinancialsList"
  });

  console.log(permitItemFinancials);

  return {
    permitItemFinancials,
    permitItemFinancialsArePending,
    permitItemFinancialsHaveLoaded,
    paginationData,
    latestQuery
  };
}

export function usePermitItemTrackerDatatable() {
  const itemTrackingHeaders = [
    { text: "ITEM NO.", value: "item_num" },
    { text: "QTY (Act./Est.)", align: "right", value: "actual_qty" },
    { text: "COST", align: "right", value: "actual_cost" },
    { text: "", align: "center", value: "actions", sortable: false }
  ];

  return {
    itemTrackingHeaders
  };
}
