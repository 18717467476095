import {
  mdiFileQuestionOutline,
  mdiLightbulbVariantOutline,
  mdiRemote,
  mdiTrafficLightOutline
} from "@mdi/js";
export const permitTypeIcon = (permitType) => {
  switch (permitType) {
    case "Signal":
      return mdiTrafficLightOutline;

    case "ITMS":
      return mdiRemote;

    case "Lighting":
      return mdiLightbulbVariantOutline;

    default:
      return mdiFileQuestionOutline;
  }
};
