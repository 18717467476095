/**
 * @file Roles
 * @author Anne Canoune
 * @version 0.1.0
 * @description All of the queries associated with the Roles service
 */

/**
 * Find All Roles
 * @description Returns all roles.
 *
 *
 * @returns {object}
 */

function findAllRoles() {
  return {
    query: {
      $sort: {
        role_name: 1
      }
    }
  };
}
exports.findAllRoles = findAllRoles;
