var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permitItemFinancialsHaveLoaded)?_c('div',[_c('v-card',{attrs:{"flat":"","id":"invoice-list"}},[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between flex-wrap"},[_c('div',{staticClass:"d-flex title"},[_vm._v("Item Tracking")]),_c('div',{staticClass:"d-flex"},[_c('item-filter-menu')],1)]),(_vm.latestQuery)?_c('v-data-table',{attrs:{"headers":_vm.itemTrackingHeaders,"items":_vm.latestQuery.response.data,"options":_vm.options,"server-items-length":_vm.latestQuery.response.total,"loading":_vm.permitItemFinancialsArePending,"loading-text":"Loading... Please wait","item-key":"estimate_item_id","disable-sort":"","footer-props":{
        itemsPerPageOptions: [5, 10, 25, 50]
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.item_num",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.item_num)?_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"black--text font-weight-medium",staticStyle:{"max-width":"275px"}},[_vm._v(_vm._s(item.item_desc))]),_c('small',{staticClass:"black--text font-weight-light"},[_vm._v("#"+_vm._s(item.item_num))])]):_vm._e()])]}},{key:"item.actual_qty",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:item.remaining_qty >= 0
              ? 'black--text font-weight-medium'
              : 'error--text font-weight-medium'},[_vm._v(" "+_vm._s(Number.parseFloat(item.actual_qty).toFixed(2))+" ")]),_vm._v(" / "+_vm._s(Number.parseFloat(item.estimate_qty).toFixed(2))+" ")]}},{key:"item.actual_cost",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:item.remaining_cost < 0
              ? 'error--text font-weight-medium mb-1'
              : 'black--text font-weight-medium mb-1'},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.actual_cost))+" ")]),_c('v-progress-linear',{attrs:{"value":item.remaining_percent,"color":_vm.resolvePercentRemainColor(
              item.estimate_qty,
              item.actual_qty,
              item.remaining_percent
            ),"rounded":""}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('v-card',{attrs:{"dark":""}},[_c('v-card-text',[_c('estimate-item-quick-view',{attrs:{"itemData":item}})],1)],1)],1)]}}],null,true)}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }