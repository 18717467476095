import { computed } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { findAllRoles } from "@/store/queries/roles";

const { Role } = Vue.$FeathersVuex.api;

export function useRoleList() {
  const rolesParams = computed(findAllRoles);

  const { items: roles, isPending: rolesArePending } = useFind({
    model: Role,
    params: rolesParams,
    qid: "roles"
  });

  // When adding a new user we should not offer the
  // option of Super Administrator.
  const rolesForNewUser = computed(() => {
    return roles.value.filter(
      (role) => role.roleName !== "Super Administrator"
    );
  });

  return {
    roles,
    rolesArePending,
    rolesForNewUser
  };
}
